// USER LOGIN

.login__wrap{
    min-height: 100vh;
    height: max-content;
    background-color: #B5B1B0;
    background-image: url('../../images/cydeo-login-bg.jpg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 0;
    .login__side{
        max-width: 100%;
        position: relative;
        width: 875px;
        min-height: 100vh;
        height: max-content;
        background-color: #fff !important;
        padding-top: 2rem;
        padding-bottom: 2rem;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before{
            content: '';
            position: absolute;
            left: 100%;
            top: 0;
            height: 100%;
            width: 20px;
            background-color: $blue_main;
            box-shadow: inset 7px 1px 15px rgba(0, 0, 0, 0.5);
        }
        .login__cont{
            width: 650px;
            min-height: 100%;
            height: max-content;
            max-width: 100%;
            margin: auto;
            padding-left: 1rem;
            padding-right: 1rem;
            @media screen and (max-width: 1080px) {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                min-height: auto;
            }
            h1{
                font-size: 33px;
                margin-bottom: 3rem;
                color: $blue_main;
                font-weight: bold;
                @media screen and (max-width: 1080px) {
                    font-size: 22px;
                    margin-bottom: 2rem;
                    text-align: center;
                }
            }
            .login__image{
                width: 150px;
                margin-bottom: 2rem;
            }
            .login__form{
                @media screen and (max-width: 1080px) {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
                button.login__submit{
                    width: 100%;
                    margin-top: 0.75rem;
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    background-color: $blue_main;
                    font-size: 16px;
                    border-radius: 7.5px;
                    transition: all 0.25s;
                    border: 0;
                    cursor: pointer;
                    margin-bottom: 2rem;
                    &:hover{
                        background-color: $purple_main;
                    }
                }
                .login__error{
                    background-color: $red_dark;
                    padding: 1rem;
                    font-size: 12px;
                    color: #fff;
                    margin-bottom: 1rem;
                    margin-top: -1.5rem;
                    border-radius: 5px;
                }
                .login__item{
                    margin-bottom: 1rem;
                    .form__label{
                        font-weight: bold;
                        font-size: 16px;
                        color: $grey1;
                    }
                    input.input{
                        height: 3rem;
                        border-radius: 7.5px;
                        font-size: 16px;
                        &:focus{
                            border-color: $purple_main;
                            box-shadow: 0 0 0 0.25rem #4a28f577;
                        }
                    }
                    .form__text{
                        margin-top: 0.5rem;
                        margin-bottom: 3rem;
                        p{
                            font-size: 14px;
                            color: $black1;
                        }
                        
                    }
                }
                
            }
        }
    }
}