@import url('./bulma/bulma.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$blue_main: #01C9FF;
$purple_main: #9379FE;
$orange_main: #F08132;
$grey1: #707070;
$grey2: #7070707c;
$grey3: #737373;
$grey4: #7070703b;
$blue_light: #3f89ff1e;
$black1: #3E3E3E;
$black2:#282828;
$black3: #040533;
$green_main: #31FF00;
$green_dark: #84D254;
$green_light: #BCF296;
$red_light: #F5CAC5;
$red_dark: #E15847;
$blue_light2: #E5F3FE;

// GLOBAL

h1, h2, h3, h4, h5, h6, p, label, button, ol, ul, li, th, tr, select, input {
    font-family: 'Poppins', sans-serif;
}
a{
    text-decoration: none !important;
}

@import './additional/login.scss';


// MAIN PAGE

// NAVIGATION

.nav__wrap{
    position: fixed;
    left: 0;
    top: 0;
    background-color: #f4f4f4;
    width: 275px;
    height: 100vh;
    padding: 2rem 1.5rem;
    box-shadow: 4px 1px 5px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 4px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 4px 1px 5px 0px rgba(0,0,0,0.15);
    
    .nav__image{
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        img{
            width: 125px;
        }
        margin-bottom: 2rem;
        h2{
            font-weight: bold;
            color: $blue_main;
            margin-top: 0.5rem;
            font-size: 19px;
        }
        button.__hamburger{
            display: none;
        }
    }
    .nav__items__wrap{
        display: block;
        button{
            width: 100%;
            background-color: transparent;
            border: none;
            text-align: left;
            justify-content: flex-start;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 0.5rem;
            transition: all 0.1s;
            &.active{
                background-color: $blue_main;
            }
            &:hover{
                background-color: $purple_main;
            }
        }
    }
    .nav__logout{
        position: absolute;
        bottom: 2rem;
        left: 0;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        button{
            width: 100%;
            border: 0;
            background-color: $red_dark;
            color: #fff;
            transition: all 0.1s;
            &:hover{
                background-color: $red_light;
                color: #000;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .nav__wrap{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        z-index: 99999;
        padding: 1rem !important;
        padding-top: 1.5rem !important;
        .nav__image{
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0;
            display: flex;
            justify-content: space-between;
            img{
                width: 100px;
            }
            margin-bottom: 0.5rem;
            h2{
                display: none;
            }
            button.__hamburger{
                padding: none;
                border: none;
                background-color: transparent;
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
                svg{
                    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(50deg) brightness(118%) contrast(119%);
                }
                &:hover{
                    svg{
                        filter: invert(0);
                    }
                }
            }
        }
        .nav__items__wrap{
            display: none;
            padding-top: 1.5rem;
            &.__active{
                display: block;
            }
        }
        .nav__logout{
            padding: 0 !important;
            position: relative;
            bottom: 0;
            display: none;
            &.__active{
                display: block;
            }
        }
    }
}

// MAIN CONTAINER

.main__section{
    background-color: #fff;
    width: 100%;
    padding-left: 275px;
    .main__container{
        padding: 3rem;
        width: 100%;
        h1{
            font-size: 26px;
            color: #000;
            font-weight: 500;
            // margin-bottom: 1rem;
            // text-decoration: underline;
            // text-decoration-color: $blue_main;
        }
        .__divider{
            margin-top: 1rem;
            margin-bottom: 1rem;
            height: 5px;
            width: 100%;
            background-color: #f4f4f4;
        }
        // MAKE
        .make__wrap{
            
            .make__cont{
                margin-top: 2.5rem;
                
                button.button{
                    border: none;
                    color: #fff;
                    background-color: $green_dark;
                    &:last-child{
                        margin-left: 1rem;
                        background-color: $red_dark;
                    }
                    &:hover{
                        background-color: $purple_main;
                        transition: all 0.1s;
                    }
                }
                &.__alt{
                    button.button{
                        border: none;
                        color: #fff;
                        background-color: $green_dark;
                        margin-left: 0;
                        &:hover{
                            background-color: $purple_main;
                            transition: all 0.1s;
                        }
                    }
                }
            }

            h2{
                font-size: 20px;
                margin-bottom: 1.5rem;
                font-weight: 500;
                margin-top: 0.5rem;
            }
            .field__error{
                background-color: $red_dark;
                padding: 1rem;
                margin-top: 0.5rem;
                font-size: 12px;
                color: #fff;
                margin-bottom: 1rem;
                border-radius: 5px;
                max-width: 80%;
            }
            .field{
                max-width: 80%;
                label{
                    font-weight: 400;
                    font-size: 14px;
                    &.radio{
                        margin-right: 0.5rem;
                        font-size: 14px;
                        input{
                            margin-right: 0.25rem;
                        }
                    }
                }

                input.input{
                    height: 37.5px;
                    font-size: 14px;
                }

                .select{
                    height: 37.5px;
                    font-size: 14px;
                    &::after{
                        top: 47.5%;
                        border-color: $blue_main;
                    }
                }
                
                .__uni{
                    display: flex;
                    align-items: center;
                    h3{
                        font-weight: bold;
                        font-size: 20px;
                        margin-left: 1rem;
                        
                    }
                    button.button{
                        background-color: $purple_main;
                        color: #fff;
                        border: none;
                        margin-left: 1rem;
                        transition: all 0.1s;
                        font-size: 15px;
                        &:hover{
                            background-color: $purple_main;
                        }
                    }
                }
            }
        }
        // ORDER
        .orders__wrap{
            .orders__cont{
                margin-bottom: 1rem;
                button{
                    padding: 1rem;
                    border: 0;
                    font-size: 15px;
                    background-color: $purple_main;
                    color: #fff;
                    transition: all 0.1s;
                    &.__delete{
                        background-color: $red_dark !important;
                        margin-left: 0 !important;
                        &.__inactive{
                            background-color: #000 !important;
                            cursor: not-allowed;
                        }
                        img{
                            width: 15px;
                            margin-right: 0.5rem;
                            filter: invert(1);
                        }
                    }
                    &:hover{
                        background-color: $green_dark !important;
                    }
                    &:last-child{
                        margin-left: 0.5rem;
                        background-color: $blue_main;
                    }
                }
            }

            
            table.table{
                button.__select{
                    padding: 0;
                    height: 18px;
                    width: 18px;
                    border-color: #000;
                    position: relative;
                    background-color: #f4f4f4;
                    &::before{
                        display: none;
                        content: '';
                        width: 12px;
                        height: 12px;
                        background-image: url('../images/cydeo-check.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        right: 2px;
                        top: 2px;
                        position: absolute;
                        filter: brightness(0) invert(1);
                    }
                    &.__active{
                        background-color: $purple_main;
                        &::before{
                            display: block;
                        }
                    }
                }
                button.__edit{
                    font-size: 12px;
                    padding: 0.25rem 0.5rem;
                    border: none;
                    background-color: $green_dark;
                    color: #fff;
                    transition: all 0.2s;
                    display: flex;
                    align-items: center;
                    img{
                        width: 13px;
                        margin-right: 0.25rem;
                        filter: invert(1);
                    }
                    &:hover{
                        background-color: $orange_main;
                    }
                }
                tr.__selected{
                    background-color: #f4f4f4;
                }
                td{
                    font-size: 14px;
                }
                th{
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 1080px) {
        padding-left: 0;
        padding-top: 5rem;
        .main__container{
            padding: 2rem;
            h1{
                text-align: center;
            }
            .orders__wrap{
                .orders__cont{
                    width: 100%;
                    display: block;
                    button.button{
                        width: 100%;
                        &:last-child{
                            margin-left: 0;
                            margin-top: 0.5rem;
                        }
                    }
                }
            }
            .make__wrap{
                h2{
                    text-align: center;
                    margin-top: 1rem;
                }
                .select{
                    width: 100%;
                    select{
                        width: 100%;
                    }
                }
                .field{
                    max-width: 100%;
                    text-align: center;
                }
                .field__error{
                    max-width: 100%;
                }
                .make__cont{
                    button.button{
                        width: 100%;
                        &:last-child{
                            margin-left: 0;
                            margin-top: 0.5rem;
                        }
                    }
                }
            }
        }
        
        
    }
}
